<template>
  <div>
  </div>
</template>
<script lang="ts">
  import {defineComponent, getCurrentInstance, onMounted} from "vue";
  import { getToken } from "../https/api";
  export default defineComponent({
    setup() {
      const { proxy }: any = getCurrentInstance(); //实例
      onMounted(() => {
        // 浏览器访问时：监听来源于浏览器！！！！！！！！！！！！
        window.addEventListener('message',function(e){
          var data = e.data;
          var origin = e.origin;
          // 监听origin为uap且动作为routerPsh时执行指定url路由跳转
          if(origin != process.env.VUE_APP_UAP_SERVER_PATH){
            return false;
          }
          var dataJson = JSON.parse(data);
          var cmd = dataJson?.cmd;
          //接收UAP发送的token数据
          if(cmd == "routerPush"){
            proxy.$router.push(dataJson.url);
          }
        },false);

        //从网关向UAP获取token和功能权限
        getToken({systemCode: "MTMS",}).then(function (response: any) {
          let res = JSON.parse(response.data);
          sessionStorage.setItem("UAP_accessToken", res.accessToken);
          sessionStorage.setItem("UAP_refreshToken", res.refreshToken);
          sessionStorage.setItem("menuUrls", JSON.parse(res.menuUrls));  //菜单鉴权
          sessionStorage.setItem("resourceCodes", res.resourceCodes);  //按钮鉴权
          //向UAP发送消息，设置origin
          window.parent.postMessage('{"cmd":"logined", "systemCode":"MTMS"}',process.env.VUE_APP_UAP_SERVER_PATH);
          //登录成功，
          let route = window.location.search.substring(7);
          if (route) {
            // 客户端访问时：通过客户端配置的链接请求参数来判断路由页面！！！！！！！！！
            proxy.$router.push(route);
          } else {
            proxy.$router.push("/transport");
          }
        })
        .catch(function (error: any) {
          console.log("error:"+error);
          //向UAP发送消息，跳转到登录页面
          window.parent.postMessage('toLogin',process.env.VUE_APP_UAP_SERVER_PATH);
        });
      })
    }
  })
</script>


